import { Component, EventEmitter, Input, OnChanges, OnInit, Output, forwardRef } from '@angular/core';
import { FormBuilder, FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-search-comp',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppSearchComponent),
      multi: true
    }
  ]
})
export class AppSearchComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() className: string | any = '';
  @Input() placeHolder: string | any = '';

  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() blur: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEnter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();

  onChange: any = () => {};
  onTouch: any = () => {};

  value = '';
  isEnterPressed = false;
  isDisabled = false;

  constructor() {}

  ngAfterViewInit() {
    this.isDisabled = this.disabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  writeValue(val: string) {
    this.value = val;
  }

  onModelChange(e: string) {
    this.value = e;
    this.onChange(e);
  }

  onHandleKeyPress(event: KeyboardEvent) {
    const { key } = event;
    if (key === 'Enter') {
      event.preventDefault();
      this.isEnterPressed = true;
      this.onEnter.emit(this.value);
      (event.target as HTMLInputElement).blur();
    } else {
      this.change.emit();
    }
  }

  onHandleClear(event: Event) {
    this.onModelChange('');
    this.onClear.emit();
  }
  onHandleBlur(event: any) {
    if (this.isEnterPressed) {
      this.isEnterPressed = false;
      return;
    }
    this.blur.emit(event);
  }
}
