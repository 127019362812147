/* eslint-disable prettier/prettier */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HeadService } from 'src/app/core/services/head.service';

@Component({
  selector: 'app-non-logged',
  templateUrl: './non-logged.component.html',
  styleUrls: ['./non-logged.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class NonLoggedComponent implements OnInit {
  preconnectUrls = ['https://fonts.googleapis.com', 'https://fonts.gstatic.com'];
  googleFronts = [
    'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap',
    'https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp',
  ];

  constructor(
    private headService: HeadService
  ) {}

  ngOnInit(): void {
    this.head();
  }

  head() {
    this.headService.createLinkForCanonicalURL();
    this.preconnectUrls.forEach((url) => {
      this.headService.createLinkHead(url, 'preconnect');
    });
    this.googleFronts.forEach((font) => {
      this.headService.createLinkHead(font, 'stylesheet');
    });
  }
}
