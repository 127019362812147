import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilsHelper } from '../helpers/utils.helper';
import { TokenService } from './token.service';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(
    private utilsHelper: UtilsHelper,
    private tokenService: TokenService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  getS3BaseUrl = (path: string): string => {
    if (path) {
      return this.utilsHelper.isAbsolutedUrl(path) ? path : `${environment.s3_base_url}/${path}`;
    }

    return '';
  };

  getCloudFrontUrl(path: string, isSRM = true): string {
    if (!path) return '';

    if (path.includes('upload')) {
      isSRM = false;
    }

    let version = '';

    if (path.includes('?v=')) {
      [path, version] = path.split('?v=');
    }

    const domain = document.location.origin;
    const isContainHttp = path.includes('http');
    let pathname = '';

    if (isContainHttp) {
      const originDomain = new URL(path).origin;
      pathname = path.split(originDomain)[1];
    } else pathname = `/${path}`;

    const isPrivateInPath = pathname.includes('private');
    if (isSRM) {
      pathname = isPrivateInPath ? this.stripPrivatePath(pathname) : pathname;
      pathname = pathname.startsWith('/srm/private') ? pathname : `/srm/private/${pathname.replace(/^\/+/, '')}`;
    } else {
      pathname = isPrivateInPath ? pathname : `/private${pathname}`;
    }

    pathname = this.encodePathname(pathname);

    const url = `${domain}/${pathname}`;
    return version ? `${url}?v=${version}` : url;
  }

  encodePathname(pathname: string) {
    pathname = pathname.substring(1);
    return encodeURIComponent(pathname);
  }

  stripPrivatePath(path: string): string {
    return path.replace(/^\/srm\/private\/|^\/private\//, '');
  }

  initRouting = () => {
    let url = '/';
    const tenantId = this.tokenService.getTenant();

    if (tenantId) {
      url = '/db-material';
    } else {
      url = '/showroom/showroom-materials';
    }

    return url;
  };

  checkValidDate(date: string): boolean {
    return this.utilsHelper.checkValidDate(date);
  }
}
