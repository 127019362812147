import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateHelper } from 'src/app/core/helpers/translate.helper';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  @Input() message = 'お探しのページはサーバーに存在しません。';
  constructor(private router: Router, private translate: TranslateHelper) {}
  onBack() {
    this.router.navigate(['/auth/login']);
  }

  trans(key: string) {
    return this.translate.__m(key);
  }
}
