import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Tenant } from 'src/app/core/models/tenant.model';
import { TokenService } from 'src/app/core/services/token.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { environment } from 'src/environments/environment';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-domain-component',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.scss'],
  animations: [
    trigger('openCloseAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(-0%)', opacity: 0 }),
        animate('300ms ease-out', style({ transform: 'translateX(0)', opacity: 1 }))
      ])
    ])
  ]
})
export class DomainComponent implements OnInit {
  @Input() isOpenSwitchTenant = false;
  @Input() isBuyer = false;
  @Input() isPlm = false;
  @Output() closeSwitchTenant = new EventEmitter<any>();

  public domains: Tenant[] = [];

  allLocalStorageData: any = {};

  private initialized = false;

  constructor(
    private tokenService: TokenService,
    private utilsService: UtilsService,
    private cookieService: CookieService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    setTimeout(() => {
      this.initialized = true;
      this.renderer.listen('document', 'click', this.onClickOutside.bind(this));
    });
  }

  ngOnInit(): void {
    const authUser = JSON.parse(this.tokenService.getUser());

    this.domains = this.isPlm
      ? authUser.tenants.filter((tenant: any) => tenant.subDomain?.includes('plm'))
      : authUser.tenants.filter((tenant: any) => !tenant.subDomain?.includes('plm'));
  }
  onClickOutside(event: Event): void {
    if (!this.initialized) {
      return;
    }

    const clickedElement = event.target as HTMLElement;
    const componentElement = this.el.nativeElement;

    if (!componentElement.contains(clickedElement)) {
      this.isOpenSwitchTenant = false;
      this.closeSwitchTenant.emit();
    }
  }

  getLogo(url?: string) {
    if (url) {
      return this.utilsService.getCloudFrontUrl(url, false);
    } else {
      return '';
    }
  }

  switchDomain(domain: Tenant) {
    const currentTenantId = this.tokenService.getTenant();
    const targetTenantId = domain.id;

    const isSameTenant = currentTenantId === targetTenantId;

    const subDomain = this.isPlm ? domain.subDomain : `${domain.subDomain}.srm`;

    const url = `http://${subDomain}.${environment.rootDomain}`;

    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 30 * 1000);

    this.cookieService.set('jwt_token', this.tokenService.get(), expirationDate, '/', `.${environment.rootDomain}`);
    this.cookieService.set('tenant_id', domain.id ?? '', expirationDate, '/', `.${environment.rootDomain}`);

    if (!this.isBuyer && !this.isPlm && isSameTenant) {
      window.location.href = url;
    } else {
      window.open(url, '_blank');
    }
  }
}
