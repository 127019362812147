<ul>
  <li
    *ngFor="let breadcrumb of breadcrumbs$ | async"
    class="font-normal text-base"
    [class.disabled]="breadcrumb['disabled']"
  >
    <a
      [routerLink]="[breadcrumb['url']]"
      [queryParams]="breadcrumb['queryParams']"
      routerLinkActive="router-link-active"
      (click)="onClickBreadcrumb(breadcrumb)"
      >{{ breadcrumb['label'] | translate }}
    </a>
  </li>
</ul>
