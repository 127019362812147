import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { decodeJwt } from 'jose';
import { SharedModule } from 'src/app/shared/shared.module';
@Component({
  selector: 'app-download-preview',
  templateUrl: './download-preview.html',
  standalone: true,
  imports: [SharedModule]
})
export class DownloadPreviewComponent implements OnInit {
  isTokenValid = true;
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      const code = params.get('token');
      if (!code) {
        this.isTokenValid = false;
        return;
      }

      const currentTime = Math.floor(Date.now() / 1000);
      try {
        const tokenInfo: any = decodeJwt(code!);
        if (
          !tokenInfo ||
          !tokenInfo?.downloadLink ||
          !tokenInfo?.exp ||
          (!!tokenInfo?.exp && tokenInfo?.exp < currentTime)
        ) {
          this.isTokenValid = false;
          return;
        }

        this.downloadLink(tokenInfo?.downloadLink ?? '');
      } catch (error) {
        this.isTokenValid = false;
      }
    });
  }

  downloadLink(url: string) {
    const a = document.createElement('a');

    a.href = url;

    a.download = '';

    document.body.appendChild(a);

    a.click();

    this.closeTab();
  }

  closeTab() {
    setTimeout(() => {
      window.close();
    }, 1000);
  }
}
